function closeAll(trigger, target) {
    const elements = document.querySelectorAll('[data-nav-trigger], [data-nav], [data-mobile-nav-trigger]');
    elements.forEach(element => {
        if(element !== target && element !== trigger) {
            element.classList.remove('open')
        }
    });
}
export function desktopNav() {
    const triggers= document.querySelectorAll('[data-nav-trigger]');

    triggers.forEach(trigger => {
        const navContainer = trigger.closest('li').querySelector('[data-nav]');
        trigger.addEventListener('click', (e) => {
            e.preventDefault();
            e.stopPropagation();

            closeAll(e.target, navContainer);
            e.target.classList.toggle('open');
            navContainer.classList.toggle('open')
        });
    });

    document.addEventListener('click', ({ target }) => {
        if (!target.matches('[data-nav-trigger]') && !target.matches('[data-mobile-nav-trigger]')) {
            closeAll(null, null);
        }
    });
}

export function mobileNav() {
    const trigger = document.querySelector('[data-mobile-nav-trigger]');

    if(!trigger || !('mobileNavTrigger' in trigger.dataset)) return;

    const targetSelector = trigger.dataset.mobileNavTrigger;
    const target = document.querySelector('[' + targetSelector + ']');

    trigger.addEventListener('click', (e) => {
        e.preventDefault();
        e.stopPropagation();

        closeAll(e.target, target);
        e.target.classList.toggle('open');
        target.classList.toggle('open')
    });
}