import { logoSwap } from "./components/logo-swap";
import { selectReload } from "./components/select-reload";
import { accordion } from "./components/accordion";
import { desktopNav, mobileNav } from "./components/nav";
import { horizontalScroller } from "./components/horizontal-scroller";

logoSwap('header')
selectReload('[data-geoswitcher]');
accordion();
desktopNav();
mobileNav();
horizontalScroller();

